/* Temporary Fixes */
#layoutContainer {
  background-color: transparent !important;
}

#roomContainer > .OT_publisher {
  bottom: 25px;
  right: 25px;
  position: absolute;
  border-radius: 20px;
}

#roomContainer > .OT_screenshare {
  top: 25px;
  left: 25px;
  position: absolute;
  border-radius: 10px;
}

#waiting-room-video-container .OT_widget-container {
  border-radius: 10px;
  background-image: none !important;
}

#waiting-room-video-container .OT_widget-container .OT_video-poster {
  background-image: none !important;
}

#waiting-room-video-container .OT_audio-level-meter {
  display: none;
}

#waiting-room-video-container #MP_camera_publisher_default_controls {
  display: none;
}

#waiting-room-video-container > div {
  width: 340px !important;
  height: 200px !important;
}
/* Temporary Fixes */

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 15, 45, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(213, 15, 45, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 15, 45, 0);
  }
}